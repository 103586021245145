import React, { Component } from 'react'
import { statusCodes, codeStylesDark } from '../util/Enums';
import { store } from '../store';
import { setStatus } from '../actions/index.js';
import { GOOGLE_MAPS_API_KEY, FRONTEND_URL, BACKEND_URL } from '../util/Constants';
import GoogleMapReact from 'google-map-react'
import ReactTooltip from 'react-tooltip';
import FormatedDateTime from './FormatDateTime.js'
import PageWrapper from '../wrappers/Page';
import { Redirect } from 'react-router-dom';

const MINIMUM_WIDTH_THRESHOLD = 380;
const MOBILE_WIDTH_THRESHOLD = 550;

class Map extends Component {
  boundResizeFunc = null;

  constructor(props) {
    super(props);
    this.state = {
      endpoint: BACKEND_URL + '/api/status/', //url for accessing the backend status api
      left: 50,
      shrinkFactor: 0.5,
      leftPxOffset: -3,
      topPxOffset: 0,
      updated: "",
      zoomScale: 1,
    }

    this.mapContainerRef = React.createRef();
    this.mapImageRef = React.createRef();

    this.boundResizeFunc = this.onResize.bind(this);
    this.boundUpdateZoom = this.updateZoom.bind(this);
  }

  componentWillMount() {
    this.fetchMapData();

    //rerender the component every minute (60 * 1000 = 60000)
    this.interval = setInterval(() => this.fetchMapData(), 60000);
    window.addEventListener('resize', this.boundResizeFunc);
    this.boundResizeFunc();
  }

  componentDidMount() {
    console.log('Autoscrolling...', this.mapContainerRef.current);
    if (this.mapContainerRef.current && this.mapImageRef.current) {
        window.setTimeout(() => {
        let m = this.mapContainerRef.current;
        let i = this.mapImageRef.current;
        let top = (i.clientHeight * 0.5);
        let left = (i.clientWidth * 0.5) - (m.clientWidth * 0.6);
        console.log('Yikes: ', i.clientHeight, i.clientWidth, '...', m.clientHeight, m.clientWidth, '...', top, left);
        this.mapContainerRef.current.scrollTop = top;
        this.mapContainerRef.current.scrollLeft = left;
        });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    window.removeEventListener('resize', this.boundResizeFunc);
  }

  onResize() {
    if (window.innerWidth <= MINIMUM_WIDTH_THRESHOLD) {
        //if (this.state.shrinkFactor != 0.6) {
            this.setState({shrinkFactor: 0.6 * this.state.zoomScale, leftPxOffset: -3, topPxOffset: 0});
        //}
    } else if (window.innerWidth <= MOBILE_WIDTH_THRESHOLD) {
        //if (this.state.shrinkFactor != 0.75) {
            this.setState({shrinkFactor: 0.75 * this.state.zoomScale, leftPxOffset: -3, topPxOffset: 0});
        //}
    } else {
        //if (this.state.shrinkFactor != 1) {
            this.setState({shrinkFactor: 1 * this.state.zoomScale, leftPxOffset: 0, topPxOffset: 0});
        //}
    }
  }

  updateZoom() {
    this.state.zoomScale = this.state.zoomScale == 1 ? 0.5 : 1
    this.boundResizeFunc();
    if (this.mapContainerRef.current && this.mapImageRef.current) {
        window.setTimeout(() => {
        if (this.mapContainerRef.current && this.mapImageRef.current) {
            let m = this.mapContainerRef.current;
            let i = this.mapImageRef.current;
            let top = (i.clientHeight * 0.5);
            let left = (i.clientWidth * 0.5) - (m.clientWidth * 0.6);
            this.mapContainerRef.current.scrollTop = top;
            this.mapContainerRef.current.scrollLeft = left;
        }
        });
    }
  }

  updateTime = () => {
    var objToday = new Date(),
      curYear = objToday.getFullYear(),
      curMonth = objToday.getMonth() + 1 < 10 ? "0" + (objToday.getMonth() + 1) : objToday.getMonth() + 1,
      curDay = objToday.getDate() < 10 ? "0" + objToday.getDate() : objToday.getDate(),
      curHour = objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours(),
      curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
    var today = curMonth + "/" + curDay + "/" + curYear + " " + curHour + ":" + curMinute;
    this.setState({ updated: today })
  }

  fetchMapData = () => {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/status');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("status table load failure");
    }).then(jsonData => {
      store.dispatch(setStatus(jsonData))
      this.updateTime();
    }).catch((error) => { })
  }
 
  render() {
    let containerStyle = {
        width: '100%',//472 * this.state.shrinkFactor +'px',
        height: '100%',//354 * this.state.shrinkFactor + 'px',
        position: 'relative',
        //top: this.state.zoomScale == '1' ? '70px' : '300px',
        top: '0',
        'box-shadow': '3px 3px 8px black',
    };
    window.supState = this.state;

    return (
      <div>
        <div className="container-fluid small-font">
          <div className="mt-2">Click or tap an ED icon to show its status.</div>
          <div className="mt-2">{"Last Updated at: " + this.state.updated} </div>
          <div style={{ margin: '4px' }}>
            <button class="btn" style={{ background: 'green', color: 'white' }} onClick={this.boundUpdateZoom}>Zoom {this.state.zoomScale == '1' ? 'out' : 'in'}</button>
          </div>
        </div>
        <div style={{ height: '600px', width: '100%' }} className="map-container" ref={this.mapContainerRef}>
          <div style={containerStyle}>
            <img src={require('../COTS_map.svg')} style={{
                position: 'absolute',
                //top: `calc(472px * ${this.state.shrinkFactor} * -4.06)`,
                //left: `calc(472px * ${this.state.shrinkFactor} * -5.19)`,
                top: '0px',
                left: '0px',
                width: `calc(4720px * (0.6784) * ${this.state.shrinkFactor})`,
                height: `calc(5290px * (0.6784) * ${this.state.shrinkFactor})`,
                //width: `calc(${this.state.shrinkFactor} * 5750px)`,
                //height: `calc(${this.state.shrinkFactor} * 3589px)`,
            }} ref={this.mapImageRef}></img>
            {this.props.statusList.map((status, index) => {
              return (<MapMarker key={index}
                ed={status.ED.name}
                system={status.ED.system.abbreviation}
                left={status.ED.mapLeft * this.state.shrinkFactor}
                top={status.ED.mapTop * this.state.shrinkFactor}
                time={status.updateDateTime}
                status={statusCodes[status.statusLevel]}
                className={codeStylesDark[status.statusLevel] + " d-flex justify-content-center align-items-center map-marker"}
                shrinkFactor={this.state.shrinkFactor}
                leftPxOffset={this.state.leftPxOffset + (1176 * this.state.shrinkFactor)}
                topPxOffset={this.state.topPxOffset + (1915 * this.state.shrinkFactor)}
              />)
            })}
          </div>
        </div>
      </div>
    );
  }
}

class MapMarker extends Component {
  render() {

    const markerStyle = {
      height: 30 * this.props.shrinkFactor + "px",
      width: 30 * this.props.shrinkFactor + "px",
      textAlign: 'center',
      fontSize: (this.props.shrinkFactor < 1 ? 10 : 12) + 'px',
      fontWeight: 'bold',
      borderRadius: '50%',
      position: 'absolute',
      left: this.props.left + this.props.leftPxOffset + 'px',
      top: this.props.top + this.props.topPxOffset + 'px',
    }

    let tooltipContent = this.props.ed + '\n' + this.props.status + '\n';

    return (
      <div className={this.props.className} data-tip={tooltipContent} data-for={"marker-" + this.props.ed} style={markerStyle}>
        <span className="my-3">{this.props.system}</span>
        {/*
        */}
        <ReactTooltip event="click" globalEventOff="click" id={"marker-" + this.props.ed} type='light' effect='float'>
          <div>{this.props.ed}</div>
          <div>{this.props.status}</div>
          <FormatedDateTime visible={true} dt_str={this.props.time} />
        </ReactTooltip>
      </div>
    )
  }
}

export default PageWrapper('ED Status Map', 'Map')(Map);
